import React from "react";
import { findObjectUseCase } from "../../../usecases";
import { Table } from "nq-component";
import EmployeeId from "../../../components/EmployeeId";
import printComponent from "../../../printComponent";
import employee from "../../../employee.json";

function EmployeePage() {
  // const [employee, setEmployee] = React.useState([]);
  const [objects, setObjects] = React.useState([]); // Assuming objects is an array in your state
  const [object, setObject] = React.useState([]);
  const [prints, setPrints] = React.useState([]);
  const rePrintRef = React.useRef();

  const find = findObjectUseCase();

  // async function fetchEmployee() {
  //   try {
  //     const data = await find.execute("employee");
  //     setEmployee(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  console.log("employee", employee);

  // const onPrint = (index) => {
  //   const selectedObject = employee[index];
  //   console.log("print-user", selectedObject);

  //   setObject(selectedObject);
  //   printComponent(rePrintRef.current, "senior-ids")
  //     .then((result) => {
  //       console.log(result);
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // };

  const setStatePromise = (index) => {
    return new Promise((resolve) => {
      setPrints(index);
      resolve();
    });
  };

  const onPrint = (index) => {
    const selectedObject = employee[index];
    setStatePromise(selectedObject)
      .then(() => printComponent(rePrintRef.current, "residents"))
      .then((result) => {
        console.log("result", result);
      })
      .catch((error) => {
        alert(error);
      });
  };

  // React.useEffect(() => {
  //   fetchEmployee();
  // }, []);

  console.log("wawa", prints);

  return (
    <>
      <div className="d-none-2">
        <div ref={rePrintRef}>
          <EmployeeId employees={prints} o={object} />
        </div>
      </div>
      <div className="overflow-auto">
        <div className="p-3 p-lg-4">
          <div className="d-flex justify-content-between align-items-center">
            {" "}
            <h1 className="fw-bold mt-3 mb-4 text-capitalize">Employees</h1>
          </div>
          <table class="table table-striped">
            <thead className="">
              <tr style={{ backgroundColor: "red", color: "white" }}>
                <th
                  className="fs-xs align-middle text-nowrap text-uppercase"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  FIRST NAME
                </th>
                <th
                  className="fs-xs align-middle text-nowrap text-uppercase"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  MIDDLE NAME
                </th>
                <th
                  className="fs-xs align-middle text-nowrap text-uppercase"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  LAST NAME
                </th>
                <th
                  className="fs-xs align-middle text-nowrap text-uppercase"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  POSITION
                </th>
                <th
                  className="fs-xs align-middle text-nowrap text-uppercase"
                  style={{ backgroundColor: "black", color: "white" }}
                >
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody>
              {employee.map((e, index) => {
                return (
                  <tr>
                    <td className="fs-sm text-truncate">{e.firstName}</td>
                    <td className="fs-sm text-truncate">{e.middleName}</td>
                    <td className="fs-sm text-truncate">{e.lastName}</td>
                    <td className="fs-sm text-truncate">{e.position}</td>

                    <td className="fs-sm text-truncate">
                      <button
                        className="btn btn-primary btn-sm fs-xs shadow-none"
                        onClick={() => onPrint(index)}
                      >
                        Print
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default EmployeePage;
