function getPicture(resident) {
    if (resident.picture) {
        return resident.picture
    }
    if (resident.sex && resident.sex.charAt(0).toUpperCase() === 'M') {
        return '/placeholder-male.jpeg'
    }
    if (resident.sex && resident.sex.charAt(0).toUpperCase() === 'F') {
        return '/placeholder-female.jpeg'
    }
    return "https://via.placeholder.com/100x100?text=No+Picture"
}

export default getPicture;