import React from "react";
import solutions from "../solutions.json";
import clients from "../clients.json";
import cycles from "../cycles.json";
import tech from "../tech.json";
import contacts from "../contacts.json";
import { InputString, InputNumber, InputText, Button } from "nq-component";
import { upsertUseCase } from "../usecases";
import { useNavigate } from "react-router-dom";

const upsert = upsertUseCase();

function Main() {
  const [change, setChange] = React.useState({});
  const [progress, setProgress] = React.useState(false);
  const navigate = useNavigate();

  async function onSubmit(e) {
    e.preventDefault();
    setProgress(true);
    try {
      change.source = "Website";
      await upsert.execute("leads", change);
      navigate("/thanks");
    } catch (e) {
      console.error(e);
    }
    setProgress(false);
  }

  function onChange(field, value) {
    setChange((state) => ({
      ...state,
      [field]: value,
    }));
  }

  const style = {
    marginTop: "200px",
  };
  return (
    <>
      <hr className="m-0" />
      <div className="overflow-auto" id="scrollableDiv">
        <div className="arrow-animation">
          <img src="/logo-animation-dark.png" />
        </div>
        <div className="container p-3 px-lg-5 py-lg-4">
          <section
            id="home"
            className="text-center d-flex"
            style={{ height: `calc(100vh - 120px)` }}
          >
            <div className="m-auto">
              <h1 className="display-3 fw-bold">
                Your Partner in Business Transformation
              </h1>
              <h5 className="mt-3">
                Discover the Power of Mobile-First Web Applications
              </h5>
              <a href="#more" className="btn btn-primary mt-5">
                LEARN MORE
              </a>
            </div>
          </section>
          <section id="more" className="mt-5">
            <div className="text-center">
              <h2 className="fw-bold">Reinvent Your Workflows</h2>
            </div>
            <p className="mt-3 text-center">
              Experience the next generation of productivity with MWeeb Inc.
              Revolutionize your workflows and smoothly shift from manual to
              digital using our user-friendly mobile-first web applications. Our
              innovative solutions seamlessly adapt to mobiles, tablets, and
              desktops, empowering your business operations with flexible and
              streamlined processes for maximum efficiency.
            </p>
            <div className="row align-items-end px-5">
              <div className="col-6 col-md-2">
                <img className="img-fluid" src="/mobile.png"></img>
              </div>
              <div className="col-6 col-md">
                <img className="img-fluid" src="/tablet.png"></img>
              </div>
              <div className="col-12 col-md-7">
                <img className="img-fluid" src="/desktop.png"></img>
              </div>
            </div>
          </section>
          <section id="solutions" style={style} className="text-center">
            <h2 className="fw-bold">Providing you a solution</h2>
            <div className="row">
              {solutions.map((solution, index) => (
                <div key={"solution-" + index} className="col-md-3 mt-5">
                  <img
                    className="invert"
                    height="90"
                    src={solution.icon}
                    alt={solution.title}
                  />
                  <h5 className="mt-3">{solution.title}</h5>
                  <p>{solution.description}</p>
                </div>
              ))}
            </div>
          </section>

          <section id="services" style={style} className="text-center">
            <h2 className="fw-bold">Our Process</h2>
            <div className="row">
              {cycles.map((cycle, index) => (
                <div key={"cycle-" + index} className="col-md-4 mt-5">
                  <p>{cycle.title}</p>
                  <img
                    className="invert"
                    height="90"
                    src={cycle.icon}
                    alt={cycle.title}
                  />
                  <p className="mt-3">{cycle.description}</p>
                </div>
              ))}
            </div>
          </section>
          <section id="services" style={style} className="text-center">
            <h2 className="fw-bold">Technologies</h2>
            <p style={{ fontSize: "25px" }}>
              Our team are highly skilled and experienced in a wide range of
              latest and modern technologies.
            </p>
            <div className="row">
              {tech.map((cycle, index) => (
                <div key={"cycle-" + index} className="col-md-4 mt-5">
                  <p>{cycle.title}</p>
                  <img
                    className="invert"
                    height="100"
                    src={cycle.logo}
                    alt={cycle.title}
                  />
                  <p className="mt-3">{cycle.description}</p>
                </div>
              ))}
            </div>
          </section>
          <section id="clients" style={style} className="text-center">
            <h2 className="fw-bold">Valued Clients</h2>
            <p>A Pleasure to Work With</p>
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-9 mx-5 px-5 align-items-center">
                <div className="row">
                  {clients.map((client, index) => (
                    <div
                      key={"client-" + index}
                      className="col-6 col-md-4 mt-3"
                    >
                      <img className="img-fluid grayscale" src={client.logo} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section id="contact">
            <div className="row">
              <div className="col-md-6 mt-5">
                <h2 className="fw-bold">Message Us</h2>
                <form onSubmit={onSubmit} className="mt-3">
                  <div className="row g-3">
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Full Name</label>
                      <InputString
                        onChange={onChange.bind(this, "name")}
                        className="form-control"
                        placeholder="e.g. Juan Dela Cruz"
                        type="text"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Email Address</label>
                      <InputString
                        onChange={onChange.bind(this, "email")}
                        type="email"
                        autoComplete="nope"
                        className="form-control"
                        placeholder="e.g. username@domain.com"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Mobile No.</label>
                      <InputNumber
                        onChange={onChange.bind(this, "mobile")}
                        autoComplete="nope"
                        className="form-control"
                        placeholder="e.g. 09987654321"
                        parse={false}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Your Message</label>
                      <InputText
                        onChange={onChange.bind(this, "description")}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <Button
                      progress={progress}
                      type="submit"
                      className="btn btn-primary w-50"
                    >
                      SUBMIT
                    </Button>
                  </div>
                </form>
              </div>
              <div className="col-md-6 mt-5">
                <h2 className="fw-bold">Contact Us</h2>
                <div className="mt-3">
                  <span className="fs-sm">Contact Information</span>
                  <ul className="list-group list-group-flush">
                    {contacts.map((contact, index) => (
                      <li
                        key={"contact-" + index}
                        className="list-group-item clamp bg-transparent"
                      >
                        <i className={contact.icon} />
                        <span className="ms-2 fw-light">{contact.label}</span>
                        <span className="fs-sm text-nowrap">
                          {contact.value}
                        </span>
                      </li>
                    ))}
                    <li className="list-group-item clamp bg-transparent">
                      <i className="bi bi-globe-americas" />
                      <span className="ms-2 fw-light">Facebook: </span>
                      <a target="_blank" href="https://facebook.com/mweebinc">
                        MWeeb Inc
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <footer className="text-center">
          <p>© 2023 mweeb.com All Rights Reserved</p>
        </footer>
      </div>
    </>
  );
}

export default Main;
