import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./app/page";
import ThanksPage from "./app/ThanksPage";
import EmployeePage from "./app/employee/employee-page/EmployeePage";
import QrValidation from "./app/qr-validation/QRValidation";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thanks" element={<ThanksPage />} />
        <Route path={"/employee"} element={<EmployeePage />} />
        <Route path="/validate/qr/:userId" element={<QrValidation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
