import React from "react";
import Queue from "nq";
import ReactDOM from "react-dom/client";
import App from "./App";

Queue.setUrl("https://api.beta.innque.com/v1");
Queue.setApplicationId("MKS7NTEGpX");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
