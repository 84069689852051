import React from "react";
// import QRCode from "../QR";
// import Resident from "../Resident";
import cycles from "../../cycles.json";
import QRCode from "../QR";

const defaultProps = {
  height: "85.7mm",
  width: "54.22mm",

  hasBack: false,
  resident: {
    first_name: "Strike",
    middle_name: "B.",
    last_name: "Revilla",
    picture: "/mayor-strike.png",
    signature: "/placeholder-signature.png",
    address: "Molino 3 Bacoor City Cavite",
    birthdate: "03/02/1970",
    civil_status: "Married",
    precinct_number: "197-D",
    mobile: "09869867858",
    id_number: "09869867858",
  },
  incase_emergency: {
    first_name: "Strike",
    middle_name: "B.",
    last_name: "Revilla",
    mobile: "09869867858",
    address: "Molino 3 Bacoor City Cavite",
  },
  city: {
    name: "BACOOR",
    logo: "/bacoor-logo.png",
    mayorLogo: "/strike.png",
  },
  barangay: {
    name: "QUEENS ROW CENTRAL",
    logo: "/barangay.png",
    captain_signature: "/placeholder-signature.png",
    officials: [
      {
        name: "Strike Revilla",
        position: "PUNONG BARANGAY",
      },
    ],
  },
  valid: "12/31/23",
};

function EmployeeId({
  barangay,
  resident,
  incase_emergency,
  city,
  valid,
  width,
  height,
  hasBack,
  employees,
  o,
}) {
  const captain =
    (barangay.officials &&
      barangay.officials.find(
        (official) => official.position === "PUNONG BARANGAY"
      )) ||
    {};
  const employee = employees;

  const renderFront = () => {
    const style = {
      width: "54.22mm",
      height: "85.7mm",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    };
    return (
      <div>
        <div
          className="text-dark"
          style={{ ...style, position: "relative", display: "flex" }}
          //   style={{
          //     // backgroundColor: "red",
          //     position: "relative",
          //     width: width,
          //     height: height,
          //     backgroundRepeat: "no-repeat",
          //     backgroundSize: "contain",
          //   }}
        >
          <div
            className=""
            style={{
              height: "85.7mm",
              width: "50px",
              backgroundColor: "black",
              margin: "0px",
            }}
          >
            <div className="">
              {cycles.map((cycle, index) => (
                <div
                  key={"cycle-" + index}
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    color: "white",
                    // marginTop: "25px",
                    height: "46px", // Set a fixed height for each icon container
                  }}
                >
                  <img
                    className="text-white"
                    height="20"
                    src={cycle.icon}
                    alt={cycle.title}
                    style={{
                      filter:
                        "invert(100%) drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black)",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className=""
            style={{ height: height, width: "155px", backgroundColor: "white" }}
          >
            <div className="text-center">
              <img src="/logo.svg" style={{ marginTop: "13px" }} />
              <p
                className="text-dark fw-bold"
                style={{ fontSize: "9px", marginTop: "13px" }}
              >
                MWEEB INFORMATION TECHNOLOGY INC
              </p>
            </div>
            <div className="text-center mt-4">
              <img
                className="rounded-circle  p-0"
                src={employee?.picture}
                height="75"
                style={{ border: "2px solid black" }}
              />
            </div>
            <div className="text-center mt-2">
              <p
                className=""
                style={{ marginBottom: "-8px", fontSize: "12px" }}
              >
                {employee?.firstName} {employee?.middleName}
              </p>
              <p className="fw-bold" style={{ fontSize: "15px" }}>
                {employee?.lastName}
              </p>
            </div>
            <div className="text-center" style={{ marginTop: "-10px" }}>
              <p
                className="text-dark fw-bold"
                style={{ fontSize: "10px", marginBottom: "-3px" }}
              >
                {employee?.position}
              </p>
              <p className="text-dark" style={{ fontSize: "10px" }}>
                {employee?.employeeId}
              </p>
              {/* <p className="text-dark" style={{ fontSize: "10px" }}>
                {employee?.id}
              </p> */}
            </div>
            <div className="text-center" style={{ marginTop: "-10px" }}>
              <QRCode
                width={46}
                height={46}
                value={`https://mweeb.com/validate/qr/${employee?.id}`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBack = () => {
    const style = {
      width: "54.22mm",
      height: "85.7mm",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    };
    return (
      <div>
        <div
          className="d-flex text-dark mt-2"
          style={{ ...style, position: "relative" }}
        >
          <div
            className=""
            style={{
              height: "85.7mm",
              width: "50px",
              backgroundColor: "black",
              margin: "0px",
            }}
          >
            <div className="">
              {cycles.map((cycle, index) => (
                <div
                  key={"cycle-" + index}
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    color: "white",
                    // marginTop: "25px",
                    height: "46px", // Set a fixed height for each icon container
                  }}
                >
                  <img
                    className="text-white"
                    height="20"
                    src={cycle.icon}
                    alt={cycle.title}
                    style={{
                      filter:
                        "invert(100%) drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black)",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div
            className=""
            style={{ height: height, width: "155px", backgroundColor: "white" }}
          >
            <div className="d-flex ">
              <img
                className="ms-2"
                src="/logo.svg"
                height="20"
                style={{ marginTop: "17px" }}
              />
              <p
                className="text-dark fw-bold ms-2"
                style={{ fontSize: "8px", marginTop: "15px" }}
              >
                MWEEB INFORMATION TECHNOLOGY INC
              </p>
            </div>
            <div className="text-center " style={{ marginTop: "-12px" }}>
              <div>
                <i className="bi bi-geo-alt" style={{ fontSize: "7px" }}></i>
                <span
                  className="mb-0"
                  style={{ fontSize: "6px", marginLeft: "2px" }}
                >
                  Unit 402, De Oro Bldg, Sierra Madre St.
                </span>
                <p style={{ fontSize: "6px", marginTop: "-6px" }}>
                  {" "}
                  Malamig, Mandaluyong City
                </p>
              </div>

              <div className="" style={{ marginTop: "-23px" }}>
                <i className="bi bi-telephone" style={{ fontSize: "7px" }}></i>
                <span
                  className="mb-0"
                  style={{ fontSize: "6px", marginLeft: "2px" }}
                >
                  8555-1726
                </span>
              </div>
              <div className="" style={{ marginTop: "-15px" }}>
                <i className="bi bi-globe2" style={{ fontSize: "7px" }}></i>
                <span
                  className="mb-0"
                  style={{ fontSize: "6px", marginLeft: "2px" }}
                >
                  https://mweeb.com
                </span>
              </div>
            </div>
            <div>
              <div
                className="text-center fw-bold m-2"
                style={{ backgroundColor: "#c1bebe" }}
              >
                <p
                  className="p-1"
                  style={{ marginBottom: "-8px", fontSize: "5px" }}
                >
                  {employee?.address}
                </p>
              </div>
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "5px",
                  marginLeft: "10px",
                }}
              >
                HOME ADDRESS
              </p>
            </div>

            <div className="d-flex" style={{ marginTop: "-15px" }}>
              <div>
                <div
                  className="text-center fw-bold m-2"
                  style={{
                    backgroundColor: "#c1bebe",
                    width: "63px",
                    height: "18px",
                  }}
                >
                  <p
                    className="p-1"
                    style={{ marginBottom: "", fontSize: "5px" }}
                  >
                    {new Date(employee?.birthday).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </p>
                </div>
                <p
                  style={{
                    marginTop: "-7px",
                    fontSize: "4px",
                    marginLeft: "10px",
                  }}
                >
                  BIRTHDAY
                </p>
              </div>

              <div>
                <div
                  className="text-center fw-bold m-2"
                  style={{
                    backgroundColor: "#c1bebe",
                    width: "63px",
                    height: "18px",
                  }}
                >
                  <p
                    className="p-1"
                    style={{ marginBottom: "", fontSize: "5px" }}
                  >
                    {employee?.gender}
                  </p>
                </div>
                <p
                  style={{
                    marginTop: "-7px",
                    fontSize: "5px",
                    marginLeft: "10px",
                  }}
                >
                  GENDER
                </p>
              </div>
            </div>
            <div className="d-flex" style={{ marginTop: "-15px" }}>
              <div>
                <div
                  className="text-center fw-bold m-2"
                  style={{
                    backgroundColor: "#c1bebe",
                    width: "63px",
                    height: "18px",
                  }}
                >
                  <p
                    className="p-1"
                    style={{ marginBottom: "", fontSize: "6px" }}
                  >
                    {employee?.sss}
                  </p>
                </div>
                <p
                  style={{
                    marginTop: "-7px",
                    fontSize: "5px",
                    marginLeft: "10px",
                  }}
                >
                  SSS NUMBER
                </p>
              </div>

              <div>
                <div
                  className="text-center fw-bold m-2"
                  style={{
                    backgroundColor: "#c1bebe",
                    width: "63px",
                    height: "18px",
                  }}
                >
                  <p
                    className="p-1"
                    style={{ marginBottom: "", fontSize: "6px" }}
                  >
                    {employee?.tin}
                  </p>
                </div>
                <p
                  style={{
                    marginTop: "-7px",
                    fontSize: "5px",
                    marginLeft: "10px",
                  }}
                >
                  TIN NUMBER
                </p>
              </div>
            </div>

            <div className="d-flex" style={{ marginTop: "-15px" }}>
              <div>
                <div
                  className="text-center fw-bold m-2"
                  style={{
                    backgroundColor: "#c1bebe",
                    width: "63px",
                    height: "18px",
                  }}
                >
                  <p
                    className="p-1"
                    style={{ marginBottom: "", fontSize: "5px" }}
                  >
                    {employee?.inCaseEmergency}
                  </p>
                </div>
                <p
                  style={{
                    marginTop: "-7px",
                    fontSize: "5px",
                    marginLeft: "10px",
                  }}
                >
                  INCASE OF EMERGENCY
                </p>
              </div>

              <div>
                <div
                  className="text-center fw-bold m-2"
                  style={{
                    backgroundColor: "#c1bebe",
                    width: "63px",
                    height: "18px",
                  }}
                >
                  <p
                    className="p-1"
                    style={{ marginBottom: "", fontSize: "5px" }}
                  >
                    {employee?.emergencyContactNo} {employee?.emergencyRelation}
                  </p>
                </div>
                <p
                  style={{
                    marginTop: "-7px",
                    fontSize: "5px",
                    marginLeft: "10px",
                  }}
                >
                  CONTACT No | RELATION
                </p>
              </div>
            </div>

            <div className="text-center" style={{ marginTop: "-18px" }}>
              <img src={employee?.signature} height="38" />
              <p
                className="fw-bold"
                style={{
                  fontSize: "6px",
                  marginBottom: "-15px",
                  marginTop: "-4px",
                }}
              >
                {employee?.firstName} {employee?.middleName}{" "}
                {employee?.lastName}
              </p>
              <hr
                className="me-3 ms-3 mb-0"
                style={{ backgroundColor: "black" }}
              />
              <p style={{ fontSize: "6px" }}>EMPLOYEE SIGNATURE</p>
            </div>

            <div
              className="text-center"
              style={{ backgroundColor: "#ce6823", marginTop: "-12px" }}
            >
              <p style={{ fontSize: "6px", padding: "3px" }}>
                This card is property of MWeeb Information Technology Inc. If
                found please return to us.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderFront()}
      {renderBack()}
    </div>
  );
}

EmployeeId.defaultProps = defaultProps;
export default EmployeeId;
