import { Progress } from "nq-component";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import getPicture from "../../getPicture";
import { getObjectUseCase } from "../../usecases";
import employee from "../../employee.json";

const QrValidation = () => {
  const { userId } = useParams();
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(true);
  const [resident, setResident] = useState("");

  console.log("userid", userId);

  // React.useEffect(() => {
  //   // const query = {where:, keys: ['birthdate']};
  //   const get = getObjectUseCase();

  //   async function fetchCount() {
  //     try {
  //       const resident = await get.execute("employee", userId);
  //       setResident(resident);
  //       setProgress(false);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  //   fetchCount();
  //   return () => {
  //     get.abort();
  //   };
  // }, []);
  React.useEffect(() => {
    // Check if the userId exists in the employee data
    const residentData = employee.find((emp) => emp.id === userId);

    if (residentData) {
      setResident(residentData);
      setIsValid(true); // Or set this based on some condition
    } else {
      console.error("User not found");
      setIsValid(false);
    }

    setProgress(false);
  }, [userId]);

  const buttonClasses = `btn btn-white rounded-circle ${
    isValid ? "btn-success" : "btn-danger"
  }`;

  const iconClass = isValid ? "bi bi-check-lg" : "bi bi-x-lg";
  const iconColor = isValid ? "green" : "red";
  if (progress)
    return (
      <div className="bg-white ps-1">
        <Progress />
      </div>
    );
  return (
    <div
      style={{
        minHeight: "100vh",
        background: `url('/building.jpeg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className="container  d-flex justify-content-center align-items-center"
        style={{
          minHeight: "100vh",
        }}
      >
        <div className="d-flex flex-column align-items-center">
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div className="text-center">
              <h3>MWEEB ID VERIFICATION</h3>
              <div className="mb-4">
                <img
                  className="me-5"
                  src="/logo.svg"
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
                <button
                  className={buttonClasses}
                  style={{ width: "100px", height: "100px" }}
                  disabled
                >
                  <span className="btn btn-white bg-white rounded-circle">
                    <i
                      className={iconClass}
                      style={{ color: iconColor, fontSize: "24px" }}
                    ></i>
                  </span>
                </button>
              </div>
              <p className={`${isValid ? "text-success" : "text-danger"}`}>
                <b>
                  {isValid
                    ? "VERIFIED EMPLOYEE OFFICIAL"
                    : "NOT VERIFIED RESIDENT OFFICIAL"}
                </b>
              </p>
            </div>

            {isValid ? <hr /> : null}
            {isValid ? (
              <>
                <div className="text-center mb-3">
                  <img
                    src={getPicture(resident)}
                    alt=""
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
                <p>
                  <b>Name: </b>
                  {resident.firstName} {resident.middleName} {resident.lastName}
                </p>
                <p>
                  <b>Position:</b> {resident?.position || "None"}
                </p>
                <p>
                  <b>Employee ID:</b> {resident?.employeeId || "None"}
                </p>
              </>
            ) : null}
            {isValid ? <hr /> : null}
            {/* {isValid ? <Button onClick={handleClick}>Edit</Button> : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrValidation;
