import React from "react";
import {NavBar as Nav} from "nq-component";

function NavBar(props) {
    return <Nav
        className="shadow-sm"
        title={<span className="text-dark">MWeeb Inc.</span>}
        {...props}/>
}

export default NavBar;
