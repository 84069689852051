import React, { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";

const defaultProps = {
  width: 135,
  height: 135,
};

function QRCode({ value, width, height }) {
  const qrCode = new QRCodeStyling({
    width: width,
    height: height,
    type: "svg",
    // image: "/logo.svg",
    data: value,
    cornersSquareOptions: {
      type: "extra-rounded",
    },
    dotsOptions: {
      color: "black",
      type: "rounded",
    },
  });
  const ref = useRef(null);
  // useEffect(() => {
  //   qrCode.append(ref.current);
  // }, []);
  useEffect(() => {
    qrCode.append(ref.current);
    return () => {
      ref.current.innerHTML = "";
    };
  }, [value]);

  return (
    <div className="App">
      <div ref={ref} />
    </div>
  );
}

QRCode.defaultProps = defaultProps;
export default QRCode;
