import FindObjectUseCase from "./FindObjectUseCase";
import GetObjectUseCase from "./GetObjectUseCase";
import UpsertUseCase from "./UpsertUseCase";

export function upsertUseCase() {
  return new UpsertUseCase();
}

export function findObjectUseCase() {
  return new FindObjectUseCase();
}

export function getObjectUseCase() {
  return new GetObjectUseCase();
}
