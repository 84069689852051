import React from 'react';
import '../scss/index.scss';
import {OffCanvas, Menu} from "nq-component";
import NavBar from '../components/Navbar'
import Switch from "../components/Switch";
import menus from "../menus.json";
import Main from "./main";


function Home() {
    const [collapsed, setCollapse] = React.useState(false);

    function toggleDarkMode(checked) {
        if (checked) {
            document.documentElement.setAttribute('data-bs-theme', 'dark');
        } else {
            document.documentElement.removeAttribute('data-bs-theme');
        }
    }

    function onClickItem(e) {
        e.preventDefault();
        let target = e.target;
        if (target.tagName.toLowerCase() !== 'a') {
            // Loop through parent elements until an <a> tag is found
            while (target && target.tagName.toLowerCase() !== 'a') {
                target = target.parentElement;
            }
        }
        const id = target.getAttribute('href');
        const targetElement = document.querySelector(id);
        targetElement.scrollIntoView({
            behavior: 'smooth'
        });
        setCollapse(false);
    }


    return (
        <>
            <OffCanvas
                onSetShow={setCollapse}
                show={collapsed}>
                <div className="offcanvas-body">
                    <nav className="navbar-dark">
                        <div className="d-flex p-2">
                            <img className="w-100 h-auto img-fluid"
                                 style={{filter: 'invert(100%)'}} src="/logo.svg"/>
                            <div className="p-2 text-white w-75">
                                <h6 className="m-0 text-truncate">MWeeb Information</h6>
                                <p className="text-truncate m-0">
                                    Technology Inc
                                </p>
                            </div>
                        </div>
                        <hr className="dropdown-divider bg-white"/>
                        <Menu
                            menus={menus}
                            onClickItem={onClickItem.bind(this)}/>
                    </nav>
                </div>
            </OffCanvas>
            <main className="vh-100 d-flex flex-column">
                <NavBar
                    onClickNavigate={() => setCollapse(!collapsed)}
                    className="border-dark border-5"
                    action={() => <Switch onChange={toggleDarkMode}/>}/>
                <Main/>
            </main>
        </>
    )

}

export default Home;