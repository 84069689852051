import React, { useEffect } from "react";
import Main from "./main";
import Home from "./page";
import { useNavigate } from "react-router-dom";

const ThanksPage = () => {
  const [backs, setBacks] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);

    const handlePopState = () => {
      window.location.href = window.location.pathname;
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  function back() {
    navigate("/");
  }

  return (
    <div className="p-5">
      <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4 text-center">
        <p style={{ fontSize: "70px" }}>THANK YOU!</p>
        <i
          class="bi bi-check-circle"
          style={{ color: "green", fontSize: "100px" }}
        ></i>
        <div>
          <p>
            We appreciate your time and effort in submitting your information to
            us. Your submission has been received and is now being processed.
            Our team will carefully review the information you provided. If any
            further action is required from your end, we will get in touch with
            you promptly, and we will schedule a virtual screening. Thank you
            for choosing us, and we look forward to serving you. If you have any
            questions or need assistance, please don't hesitate to contact us.
          </p>
          <button onClick={back} className="btn btn-dark">
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
